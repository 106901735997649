import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './App.css';


import Home from './views/Home';

type Segment = 'booking' | 'services' | 'about' | 'prices' | 'news' |'home';

type Language = 'mk' | 'en'


function App(): JSX.Element {

    // Use useEffect hook to fetch all the data when the component mounts
    useEffect(() => {
      fetchAllData();
      if (location.pathname != "/") {
        const storedSegment = getSelectedSegmentFromLocalStorage();
        setSelectedSegment(storedSegment);
      } else {
        setSelectedSegment("home");
      }

    }, []);

    const fetchAllData = async () => {
      try {
        // const response = await fetch('https://numbers.nikolakolevski.com/api/client/services');
        // const data = await response.json();
        // setServices(data); // Update the services state with fetched data
        // const package_response = await fetch('https://numbers.nikolakolevski.com/api/client/packages');
        // const package_data = await package_response.json();
        // setPackages(package_data); // Update the services state with fetched data
        // const news_response = await fetch('https://numbers.nikolakolevski.com/api/client/news');
        // const news_data = await news_response.json();
        // setNews(news_data); // Update the services state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };



  const navigate = useNavigate();
  const location = useLocation();
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [news, setNews] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [token, setToken] = useState('');
  const [selectedSegment, setSelectedSegment] = useState<Segment>('home');

  const saveSelectedSegmentToLocalStorage = (segment: Segment) => {
    localStorage.setItem('selectedSegment', segment);
  };

  const getSelectedSegmentFromLocalStorage = (): Segment => {
    const storedSegment = localStorage.getItem('selectedSegment');
    return storedSegment ? (storedSegment as Segment) : 'home'; // Default to 'home' if nothing is found in local storage
  };


  const scrollToSegment = (segment: Segment) => {
    const element = document.getElementById(segment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };



    const fetchYoutube = async () => {
      try {
        const response = await fetch("https://numbers.nikolakolevski.com/api/youtube");
        const data = await response.json();
        setYoutube(data[0]); // Update the services state with fetched data

      } catch (error) {

        console.error("Error fetching services:", error);
      }
    };

      // Use effect hook to fetch services whenever activeView changes
      useEffect(() => {
        if (selectedSegment === 'services') {

        }
        if (selectedSegment === 'prices') {

        }
        if( selectedSegment === "news") {

          // fetchYoutube()
        }
      }, [selectedSegment]);

  const [isVisible, setIsVisible] = useState(false);


  const handleScroll = () => {
    if (location.pathname === "/admin/dashboard") {
      return
    } else {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      setIsVisible(scrollTop > 100);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return () => {

      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mapOptions = {
    center: { lat: 41.9973462, lng: 21.4279956 },
    zoom: 14,
  };

  const [isHeaderVisible, setHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/admin/dashboard") {
        return
      }
      const currentScrollPos = window.pageYOffset;
      const isScrolledUp = prevScrollPos > currentScrollPos;

      setHeaderVisible(isScrolledUp || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

   function RequireAuth({ children }: { children: JSX.Element }) {
    debugger
    const token = localStorage.getItem('token');

    if (token?.length===0) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }
    try {
      // const response = await fetch('https://numbers.nikolakolevski.com/home', {
      //   headers: {
      //     Authorization: token,
      //   },
      // });

      // if (!response.ok) {
      //   const errorData = await response.json();
      //   throw new Error(errorData.message);
      // }

      // const data = await response.json();

    } catch (error) {
      // setMessage(error.message);
    }


    return children;
  }

  const saveTokenToLocalStorage = (token:any) => {
    localStorage.setItem('token', token);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);
  const handleLogin = (newToken: string) => {
    saveTokenToLocalStorage(newToken);
    navigate('/admin/dashboard');
  };


  return (
    <>
    <Routes>
        <Route path="/" element={<Home services={services} news={news} packages={packages} goToBook= {()=>{
                     setSelectedSegment('booking');
                     navigate('/book')
                     scrollToTop()
                     saveSelectedSegmentToLocalStorage('booking')
        }} />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>

    </>
  );
}

export default App;
function useAuth() {
  throw new Error('Function not implemented.');
}

