import React, { createContext, useContext, useState, useEffect } from 'react';
import { TimeSlot , FormValues } from './interfaces';

// Define the shape of your global state
interface AppState {
  bookingInfo: BookingInfo | null;
}

interface BookingInfo {
    user:FormValues,
    packages: string[],
    services: string[],
    date:string,
    timeslots:TimeSlot[],
    // Add more properties as needed
  }

// Initial state for the global state
const initialState: AppState = {
  bookingInfo: {
    user: {
      phone: '',
      email:'',
      name:'',
      subject:'',
      surname:''

    },
    packages: [],
    services: [],
    date: '', // You can set the default date as you wish
    timeslots: [],
    // Add more properties with default values as needed
  },
};

// Create the context for the global state
const AppState = createContext<{
    state: AppState;
    setState: React.Dispatch<React.SetStateAction<AppState>>;
    updateBookingInfo: (newBookingInfo: Partial<BookingInfo>) => void;
    updateTimeslots: (newTimeslots: TimeSlot[]) => void;
    updatePackages: (newPackages: string[]) => void;
    updateServices: (newServices: string[]) => void;
    updateUserField: (fieldName: keyof FormValues,fieldValue :string ) => void;
    resetState: () => void;
    updateBookingDate: (newDate:string) => void;
  }>({
    state: initialState,
    setState: () => initialState,
    updateBookingInfo: () => {},
    updateTimeslots: () => {},
    updateServices: () => {},
    updatePackages: () => {},
    updateUserField: () => {},
    resetState: () => {},
    updateBookingDate: () => {},
  });


// Custom hook to access the global state and its setter
export const useAppState = () => useContext(AppState);

// Component that provides the AppStateContext to its children
export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const [state, setState] = useState<AppState>(() => {
      // Retrieve the state from localStorage if it exists, otherwise use initialState
      const storedState = localStorage.getItem('appState') as any;
      let state =  JSON.parse(storedState)
      if ( typeof state === 'object' && state !== null) {
        return state
      } else {
        return initialState
      }
    });

    // Save the state to localStorage whenever it changes
    useEffect(() => {
      localStorage.setItem('appState', JSON.stringify(state));
    }, [state]);

      // Update function to update the booking info in the global state
    const updateBookingInfo = (newBookingInfo: Partial<BookingInfo>) => {
        setState((prevState) => ({
        ...prevState,
        bookingInfo: {
            ...prevState.bookingInfo!,
            ...newBookingInfo,
        },
        }));
    };

    const updateTimeslots = (newTimeslots: TimeSlot[]) => {
      setState((prevState) => ({
        ...prevState,
        bookingInfo: {
          ...prevState.bookingInfo!,
          timeslots: newTimeslots,
        },
      }));
    };

    const updateServices = (newServices: string[]) => {
      setState((prevState) => ({
        ...prevState,
        bookingInfo: {
          ...prevState.bookingInfo!,
          services: newServices,
        },
      }));
    };

    const updatePackages = (newPackages: string[]) => {
      setState((prevState) => ({
        ...prevState,
        bookingInfo: {
          ...prevState.bookingInfo!,
          packages: newPackages,
        },
      }));
    };

    const updateBookingDate = (newDate: string) => {
      setState((prevState) => ({
        ...prevState,
        bookingInfo: {
          ...prevState.bookingInfo!,
          date: newDate,
        },
      }));
    };

    const resetState = () => {
      setState(initialState);
    };

    const updateUserField = (fieldName: keyof FormValues, fieldValue: string) => {
      setState((prevState) => ({
        ...prevState,
        bookingInfo: {
          ...prevState.bookingInfo!,
          user: {
            ...prevState.bookingInfo!.user,
            [fieldName]: fieldValue,
          },
        },
      }));
    };

    return (
      <AppState.Provider value={{ state, setState, updateBookingInfo,updateTimeslots,updateServices,updatePackages,resetState,updateUserField,updateBookingDate}}>
        {children}
      </AppState.Provider>
    );
  };