import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cookies from "universal-cookie";
import LogoImg from "./img/zk_logo.png"; // Adjust the path accordingly
import DadiLogo from "../img/dadi.png"; // Adjust the path accordingly
import emailjs from '@emailjs/browser';


// ... other imports ...
import Labavo from "./../img/labavo2.jpg"; // Adjust the path accordingly
import Loading from "./../img/mail (1).png"; // Adjust the path accordingly
type Position = {
  top?: string;
  left?: string;
};

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderBottom: "1px dashed #F990CB",
          color: "",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove border
              // borderBottomLeftRadius: '20px', // Add border radius to bottom-left
              // borderBottomRightRadius: '20px', // Add border radius to bottom-right
              boxShadow: "0px 16px 12px -12px rgba(0, 0, 0, 0.2)", // Box shadow at the bottom
            },
            "&.Mui-focused fieldset": {
              // border: "1px solid pink", // Add gray border when focused
              color: "white",
            },
            "& .MuiInputBase-input": {
              color: "white",
            },

            "& .MuiInputLabel-root": {
              // Style label
              color: "white", // Default label color
              "&.Mui-focused": {
                color: "#F990CB", // Pink label color when the text field is focused
              },
            },
          },
        },
      },
    },
  },
});
const MenuTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderBottom: "1px dashed #F990CB",
          color: "",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove border
              // borderBottomLeftRadius: '20px', // Add border radius to bottom-left
              // borderBottomRightRadius: '20px', // Add border radius to bottom-right
              boxShadow: "0px 16px 12px -12px rgba(0, 0, 0, 0.2)", // Box shadow at the bottom
            },
            "&.Mui-focused fieldset": {
              // border: "1px solid pink", // Add gray border when focused
              color: "black",
            },
            "& .MuiInputBase-input": {
              color: "black",
            },

            "& .MuiInputLabel-root": {
              // Style label
              color: "black", // Default label color
              "&.Mui-focused": {
                color: "#F990CB", // Pink label color when the text field is focused
              },
            },
          },
        },
      },
    },
  },
});

function Home({ services, packages, news, goToBook }: any): JSX.Element {
  const navigate = useNavigate();
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null
  );

  const points = [
    {
      color: "white",
      text: "Video",
    },
    {
      color: "white",
      text: "Contact",
    },
    {
      color: "white",
      text: "Tickets",
    },
    {
      color: "white",
      text: "Shop",
    },
    {
      color: "white",
      text: "Spotify",
    },
  ];

  const menu_list = [
    {
      color: "white",
      text: "Home",
    },
    {
      color: "white",
      text: "Contact",
    },
    {
      color: "white",
      text: "Tickets",
    },
    {},
    {
      color: "white",
      text: "Tickets",
    },
    {
      color: "white",
      text: "Shop",
    },
  ];
  const [isMenuOpened, setMenuOpened] = useState(false);

  const [positions, setPositions] = useState<Position[]>(
    new Array(points.length).fill({})
  );
  const [menu_position, setMenuPosition] = useState<Position[]>(
    new Array(points.length).fill({})
  );
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [contact, setContact] = useState(false); // Loading state
  const [youtube, setYoutube] = useState(false); // Loading state
  const [spotify, setSpotify] = useState(false); // Loading state
  const [video, setVideo] = useState(false); // Loading state
  const [circleSize, setCircleSize] = useState(0); // Loading state
  const containerRef = useRef<HTMLDivElement>(null); // Ref for the image container

  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e:any) => {
    e.preventDefault();

    if ( form.current) {
      emailjs.sendForm('service_sq4u23d', 'template_161agrt', form.current, 'MZ_B_XbOW4tKgHtTm')
        .then((result:any) => {
          form?.current?.reset();
          alert('Email sent successfully!');

            console.log(result.text);
        }, (error:any) => {
          alert('Error sending email. Please try again.');
            console.log(error.text);
        });
    };

    }


  const onImageLoad = () => {
    updatePositions();
    scrollToImageCenter();
    setImageElement(imageRef.current);
    setTimeout(() => {
      setIsLoading(false); // Optionally, handle image load failure differently
    }, 2500);
  };
  const scrollToImageCenter = () => {
    console.log(imageRef.current);
    if (imageRef.current) {
      const image = imageRef.current;
      const rect = image.getBoundingClientRect();

      const container = containerRef.current;
      const containerRect = container?.getBoundingClientRect();
      if (containerRect) {
        const scrollToX = rect.width / 2 - containerRect.width / 2;
        const scrollToY = rect.height / 2 - containerRect.height / 2;

        if (container) {
          container.scrollLeft = scrollToX + 100;
          container.scrollTop = scrollToY;
        }
      }
    }
  };

  const onImageError = () => {
    console.error("Error loading image");
  };
  const updatePositions = () => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setCircleSize(rect.width / 200);
      if ( window.innerWidth <= 1300) {
      setCircleSize(rect.width/139)
      }
      console.log("rect", rect);
      console.log("rect.top", rect.top);
      console.log("rect.left", rect.left);
      console.log("rect.left", rect.top + rect.height / 2);
      const newPositions = [
        {
          top: `${rect.height / 2.2835443038}px`,
          left: `${rect.width / 2.01411025181}px`,
        }, // Youtube
        {
          top: `${rect.height / 2.6157317187}px`,
          left: `${rect.width / 1.68378225776}px`,
        }, // Contact
        {
          top: `${rect.height / 1.3}px`,
          left: `${rect.width / 7.02745098039}px`,
        }, // Tickets
        {
          top: `${rect.height / 2.165}px`,
          left: `${rect.width / 1.4713592233}px`,
        }, // Shop
        {
          top: `${rect.height / 1.6}px`,
          left: `${rect.width / 4.6}px`,
        }, // Spotify
      ] as any;

      setPositions(newPositions);
      setIsLoading(false); // Set loading to false after positions are updated
    }
  };

  useEffect(() => {
    if (imageElement) {
      // Check if the image is already loaded (useful for cached images)
      updatePositions();
      scrollToImageCenter();
    }

    window.addEventListener("load", updatePositions);
    window.addEventListener("resize", updatePositions);

    // Cleanup
    return () => {
      window.removeEventListener("load", updatePositions);
      window.removeEventListener("resize", updatePositions);
    };
  }, []);

  const renderColoredDivs = () => {
    return points.map((point, index) => {
      let animationName = "wave-animation-50";
      // Define individual styles based on index

      let individualStyle = {
        width: circleSize.toString() + "px",
        height: circleSize.toString() + "px",
      }; // Example style for first div

      return (
        <div
          key={index}
          onClick={() => {
            // alert(index)
            switch (index) {
              default: {
                break;
              }
              case 0: {
                // setYoutube(true);
                window.open(
                  "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                  "_blank"
                );
                break;
              }
              case 1: {
                setContact(true);
                break;
              }
              case 2: {
                window.open("https://bileti.mk/e/popkultura", "_blank");
                break;
              }
              case 3: {
                window.open("https://youngdadi.com/shop", "_blank");
                break;
              }
              case 4: {
                if ( window.innerWidth <= 1300) {
                  window.open(
                    "https://open.spotify.com/artist/2CGvAUieb36608d4zDaBKq",
                    "_blank"
                  );
                } else {
                  setSpotify(true);
                }
                break;
              }
            }
          }}
          style={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center",
            background: point.color,
            borderRadius: "50%",
            position: "absolute",
            zIndex: 100 - index,
            animation: `${animationName} 2s infinite`,
            ...positions[index],
            ...individualStyle, // Apply the individual style
          }}
          onMouseEnter={(e) => e.currentTarget.classList.add("stop-animation")}
          onMouseLeave={(e) =>
            e.currentTarget.classList.remove("stop-animation")
          }
          className="color-div"
        >
          <span
            style={{
              fontSize: (circleSize + 5).toString() + "px",
              userSelect: "none",
              padding: "2%",
              background: "white",
              paddingBottom: "0.3rem",
              paddingTop: "0.3rem",
              paddingLeft: "0.8rem",
              paddingRight: "0.8rem",
              borderRadius: "5rem",
            }}
          >
            {point.text}
          </span>
        </div>
      );
    });
  };

  const renderAllButtons = () => {
    let animationName = "wave-animation-50";
    // Define individual styles based on index

    let individualStyle = {
      width: circleSize.toString() + "px",
      height: circleSize.toString() + "px",
    }; // Example style for first div

    return <></>;
  };

  // const renderVideo = () => {
  //   return (
  //     <div className="contact-modal">
  //       {/* Your contact modal content goes here */}
  //       {/* <p>Contact us content...</p> */}
  //       {/* <div>      */}
  //       <iframe
  //         width="100%"
  //         height="100%"
  //         src="https://www.youtube.com/embed/ouAt-SoCiy4?si=58v5u_HGcT38DxZU&amp;controls=0"
  //         title="YouTube video player"
  //         frameBorder="0"
  //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //         allowFullScreen
  //       ></iframe>
  //       {/* </div> */}
  //       {/* <div style={{display:'flex', maxWidth:'100%'}}>
  //       <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ouAt-SoCiy4?si=58v5u_HGcT38DxZU&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
  //       <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ouAt-SoCiy4?si=58v5u_HGcT38DxZU&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
  //       <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ouAt-SoCiy4?si=58v5u_HGcT38DxZU&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

  //       </div> */}
  //       <button onClick={() => setVideo(false)}>Close</button>
  //     </div>
  //   );
  // };

  const renderContact = () => {
    return (
      <div className="contact-modal">
        <div
          style={{
            display: "flex",
            minHeight: "5rem",
            paddingRight: "2rem",
            paddingTop: "1rem",
            maxHeight: "5rem",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <CloseIcon
            onClick={() => {
              setContact(false);
            }}
            className="pointer"
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              color: "#F990CB",
            }}
          />
        </div>
        <div className="contact_container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <span className="contact_header">CONTACT</span>
          </div>
          <div className="contact_container_1">
            <div className="contact_container_2">
              <div>
              </div>
              <div>
                {" "}
                <div className="contact_infos ddd">
                  {"TEL: +389 71 379 428 Marko  (Sponsorship)"}
                </div>
                  <div className="contact_infos ">
                    {"TEL: +389 70340499 Darko (Booking)"}
                  </div>
                <div
                  className="contact_infos"
                  style={{
                    color: "#98E2EB",
                  }}
                >
                  {"MAIL: youngdadi.info@gmail.com"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="hover contact_infos"
                  style={{
                    color: "#F990CB",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/youngdadii/",
                      "_blank"
                    );
                  }}
                >
                  {"INSTAGRAM"}
                </div>
                {/* <div
                         className="hover"
                  style={{
                    fontSize: "24px",
                    fontWeight: "400",
                    color: "#98E2EB",
                    display: "flex",
                    alignItems: "end",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/youngdadii/",
                      "_blank"
                    );
                  }}
                >
                  {"TIKTOK"}
                </div>{" "} */}
                <div
                  className="hover contact_infos"
                  style={{
                    color: "#98E2EB",
                  }}
                  onClick={() => {
                    window.open(
                      "https://open.spotify.com/artist/2CGvAUieb36608d4zDaBKq",
                      "_blank"
                    );
                  }}
                >
                  {"SPOTIFY"}
                </div>
                <div
                  className="hover contact_infos"
                  style={{
                    color: "#F990CB",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/embed/pHCETFC6Z3o?si=Do8dWoCpktzoqn0K&amp;controls=0",
                      "_blank"
                    );
                  }}
                >
                  {"YOUTUBE"}
                </div>
              </div>
            </div>
            <div
              className="mobile_hide"
              style={{ width: "5%", minHeight: "35vh" }}
            ></div>
            <div className="mobile_hide contact_form_alo">
              <div>
                <span
                  style={{
                    fontSize: "60px",
                    fontWeight: "400",
                    color: "#98E2EB",
                  }}
                >
                  {"WHATS UP ?"}
                </span>
              </div>
              <form ref={form} onSubmit={sendEmail}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap:'50px',
                justifyContent: "space-between",
              }}
            > 
            
              <input type="text" name="user_name" id="name" className="styled-input contact_view" placeholder="Name"/>
              <input type="text" name="user_surname" id="name" className="styled-input contact_view" placeholder="Surname"/>

            </div>
            <div style={{paddingTop:'1.5rem'}}> 

              <textarea name="user_email" className="styled-input contact_view"  placeholder="Email"/>
              </div>
            <div style={{paddingTop:'1.5rem'}}> 

            <textarea name="phone" className="styled-input contact_view"  placeholder="Phone"/>
            </div>
              {/* <input type="email" name="user_email" className="styled-input" /> */}
              {/* <label>Message</label> */}
              <div className="textfield-container" >
              <textarea name="message" className="styled-input contact_view"  placeholder="Message"/>
            </div>
            <div>
            <input className="my_button_reverse" type="submit" value="Submit" />
            </div>
            </form>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TextField
                    name="name"
                    className="textfield"
                    required
                    id="outlined-required"
                    placeholder="Name"
                  />
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <TextField
                    name="email"
                    className="textfield"
                    required
                    id="outlined-required"
                    placeholder="Email"
                  />
                </ThemeProvider>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="email"
                    className="textfield"
                    required
                    multiline={true}
                    rows={2}
                    id="outlined-required"
                    placeholder="Messaage"
                  />
                </ThemeProvider>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span
                  onClick={() => {
                    setContact(false);
                  }}
                  className="my_button_reverse"
                >
                  {"Submit"}
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderYoutube = () => {
    return (
      <div className="contact-modal">
        <div
          style={{
            display: "flex",
            minHeight: "5rem",
            paddingRight: "2rem",
            paddingTop: "1rem",
            maxHeight: "5rem",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <CloseIcon
            onClick={() => {
              setYoutube(false);
            }}
            className="pointer"
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              color: "#F990CB",
            }}
          />
        </div>
        <div style={{ padding: "0rem 5rem 5rem 5rem" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              minHeight: "35vh",
            }}
          >
            <div
              style={{
                width: "35%%",
                maxWidth: "35%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <span
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  fontSize: "30px",
                  color: "#F990CB",
                }}
              >
                LISTEN NOW ON
              </span>
              <span
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  fontSize: "90px",
                  marginBottom: "2rem",
                  color: "#F990CB",
                }}
              >
                YOUTUBE
              </span>
              <div
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  // background: "red",
                  minHeight: "35vh",
                  textAlign: "start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "400",
                      color: "#F990CB",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    {"Young Dadi"}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mobile_hide"
              style={{ width: "5%", minHeight: "35vh" }}
            ></div>
            <div
              style={{
                width: "60%",
                minWidth: "60%",
                maxWidth: "60%",
                // background: "red",
                minHeight: "35vh",
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  maxWidth: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ maxWidth: "60%", minWidth: "60%" }}>
                  <iframe
                    style={{ borderRadius: "20px" }}
                    width="100%"
                    height="240"
                    src="https://www.youtube.com/embed/pHCETFC6Z3o?si=Do8dWoCpktzoqn0K&amp;controls=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                <div
                  style={{
                    maxWidth: "50%",
                    minWidth: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "1rem",
                      fontSize: "30px",
                      color: "#FEFEFE",
                    }}
                  >
                    {"Young Dadi Latest Video"}
                  </span>
                </div>
              </div>
              <div
                style={{
                  margin: "2rem 0rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "100%",
                }}
              >
                <span
                  style={{
                    minWidth: "50%",
                    textAlign: "center",
                    padding: "0.5rem 1rem",
                  }}
                  onClick={() => {
                    setContact(false);
                  }}
                  className="my_button_reverse_playall"
                >
                  {"Play All"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  minWidth: "100%",
                  maxWidth: "100%",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ maxWidth: "100%", background: "red" }}>
                  <iframe
                    style={{ borderRadius: "20px" }}
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/pHCETFC6Z3o?si=Do8dWoCpktzoqn0K&amp;controls=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                <div
                  style={{
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: "20px" }}>
                    {"YOUNG DADI BESTIE [OFFICIAL VIDEO]"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSpotify = () => {
    return (
      <div className="contact-modal">
        <div
          style={{
            display: "flex",
            minHeight: "5rem",
            paddingRight: "2rem",
            paddingTop: "1rem",
            maxHeight: "5rem",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <CloseIcon
            onClick={() => {
              setSpotify(false);
            }}
            className="pointer"
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              color: "#F990CB",
            }}
          />
        </div>
        <div className="contact_container">
          <div className="contact_divs">
            <div className="another_one">
              <span
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  fontSize: "30px",
                  color: "#F990CB",
                }}
              >
                LISTEN NOW ON
              </span>
              <span className="spotify_header">SPOTIFY</span>
              <div className="spotify_div_space">
                <div>
  
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="information_contact">
                    <img
                      style={{
                        maxWidth: "4rem",
                        maxHeight: "4rem",
                        borderRadius: "100%",
                        marginRight: "1rem",
                      }}
                      src={DadiLogo}
                      about=""
                      alt=""
                    />
                    {"Young Dadi"}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mobile_hide"
              style={{ width: "5%", minHeight: "35vh" }}
            ></div>
            <div className="mobile_hide spotify_desktop">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  maxWidth: "100%",
                  justifyContent: "space-between",
                }}
              >
                <iframe
                  style={{ borderRadius: "20px" }}
                  src="https://open.spotify.com/embed/artist/2CGvAUieb36608d4zDaBKq?utm_source=generator"
                  width="100%"
                  height="560"
                  frameBorder="0"
                  allowFullScreen={false}
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div className="spot">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  maxWidth: "100%",
                  justifyContent: "space-between",
                }}
              >
                <iframe
                  style={{ borderRadius: "20px" }}
                  src="https://open.spotify.com/embed/artist/2CGvAUieb36608d4zDaBKq?utm_source=generator"
                  width="100%"
                  height="350"
                  frameBorder="0"
                  allowFullScreen={false}
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <img
            ref={imageRef}
            src={Loading}
            id="loading_img"
            alt="Room Illustration"
          />
        </div>
      )}
      {!(contact || spotify) ? (
        <>
          <div>
            <div id="desktop_menu">
              {!isMenuOpened ? (
                <>
                  <div
                    className="menu-icon menu-icon-menu"
                    onClick={(e) => {
                      setMenuOpened(!isMenuOpened);
                    }}
                  >
                    <div className="bar">
                      <div className="bar-colored"></div>
                    </div>
                    <div className="bar">
                      {/* <div className="spacer-bottom"></div> */}
                      <div style={{}} className="bar-colored"></div>
                    </div>
                    <div className="bar">
                      {/* <div className="spacer-bottom"></div> */}
                      <div className="bar-colored"></div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="menu-icon"
                    onClick={(e) => {
                      setMenuOpened(!isMenuOpened);
                    }}
                  >
                    {" "}
                    <CloseIcon
                      style={{
                        color: "white",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="image-container" ref={containerRef}>
        <img
          ref={imageRef}
          src={Labavo}
          useMap="#image_map"
          id="main_img"
          alt="Room Illustration"
          onError={onImageError}
          onLoad={onImageLoad} // Set the onLoad event
        />
        <div
          hidden={contact || youtube || spotify || isMenuOpened}
          className="colored-divs-container"
        >
          {renderColoredDivs()}
        </div>
        <div hidden={contact || youtube}>{renderAllButtons()}</div>
        <div className={contact ? "modal-container" : "hidden"}>
          {renderContact()}
        </div>
        <div className={youtube ? "modal-container" : "hidden"}>
          {renderYoutube()}
        </div>
        <div className={spotify ? "modal-container" : "hidden"}>
          {renderSpotify()}
        </div>
      </div>
      <div className={!isMenuOpened ? "menu_big_gove" : "menu_big"}>
        <div className="menu_content_left">
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className="menu_item"
                onClick={() => {
                  setContact(true);
                  setMenuOpened(!isMenuOpened);
                }}
              >
                CONTACT
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className=" menu_item"
                onClick={() => {
                  window.open("https://bileti.mk/e/popkultura", "_blank");
                }}
              >
                TICKETS
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className=" menu_item"
                onClick={() => {
                  if ( window.innerWidth <= 1300) {
                    window.open(
                      "https://open.spotify.com/artist/2CGvAUieb36608d4zDaBKq",
                      "_blank"
                    );
                  } else {
                    setSpotify(true);
                  }

                  setMenuOpened(!isMenuOpened);
                }}
              >
                SPOTIFY
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className=" menu_item"
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                    "_blank"
                  );
                }}
              >
                YOUTUBE
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className=" menu_item"
                onClick={() => {
                  window.open("https://youngdadi.com/shop", "_blank");
                }}
              >
                SHOP
              </span>
            </div>
          </div>
          <div>
            <span className="contact_info_icons">
              <YouTubeIcon
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                    "_blank"
                  );
                }}
                className="icon_css"
              />
              <InstagramIcon
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/youngdadii/",
                    "_blank"
                  );
                }}
                className="icon_css"
              />
              {/* <FacebookIcon
                   onClick={()=>{
                    window.open(
                            "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                            "_blank"
                          );
                  }}
                  className="icon_css"/> */}
              {/* <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <TikTokIcon></TikTokIcon></div> */}
              {/* <EmailIcon style={{background:'#DA327D', padding:'0.2rem', borderRadius:8, margin:'0.3rem'}} /> */}
            </span>
          </div>
        </div>
        <div className="mobile_hide menu_content_right">
          <div
            style={{
              width: "100%",
              maxWidth: "100%",
              // background: "red",
              minHeight: "35vh",
              textAlign: "start",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  fontSize: "60px",
                  fontWeight: "400",
                  color: "#98E2EB",
                }}
              >
                {"WHATS UP ?"}
              </span>
            </div>
            <form ref={form} onSubmit={sendEmail}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap:'50px',
                justifyContent: "space-between",
              }}
            > 
            
              <input type="text" name="user_name" id="name" className="styled-input" placeholder="Name"/>
              <input type="text" name="user_surname" id="name" className="styled-input" placeholder="Surname"/>

            </div>
            <div style={{paddingTop:'1.5rem'}}> 

              <textarea name="user_email" className="styled-input"  placeholder="Email"/>
              </div>
            <div style={{paddingTop:'1.5rem'}}> 

            <textarea name="phone" className="styled-input"  placeholder="Phone"/>
            </div>
              {/* <input type="email" name="user_email" className="styled-input" /> */}
              {/* <label>Message</label> */}
              <div className="textfield-container" >
              <textarea name="message" className="styled-input"  placeholder="Message"/>
            </div>
            <div>
            <input className="my_button_reverse" type="submit" value="Submit" />
            </div>
            </form>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
                
              <ThemeProvider theme={MenuTheme}>
                <TextField
                  name="name"
                  className="textfield"
                  required
                  id="outlined-required"
                  placeholder="Name"
                />
              </ThemeProvider>
              <ThemeProvider theme={MenuTheme}>
                <TextField
                  name="email"
                  className="textfield"
                  required
                  id="outlined-required"
                  placeholder="Email"
                />
              </ThemeProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <ThemeProvider theme={MenuTheme}>
                <TextField
                  sx={{ width: "100%" }}
                  name="email"
                  className="textfield"
                  required
                  multiline={true}
                  rows={2}
                  id="outlined-required"
                  placeholder="Messaage"
                />
              </ThemeProvider>
            </div> */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span
                onClick={() => {
                  
                  setContact(false);
                }}
                className="my_button_reverse"
              >
                {"Submit"}
              </span>
            </div> */}
          </div>
          <div className="contact_info">
            <div className="dropdown" style={{display:'flex', flexDirection:'column', justifyContent:'start', alignContent:'start', alignItems:'start'}}>
              <div className="dropdown-header ">
                <LocalPhoneIcon
                  style={{ paddingRight: "0.5rem", color: "#F990CB" }}
                />
                <span className="tablet_mobile">Marko +389 71 379 428 {"(Sponsorship)"}</span>
              </div>
              <div className="dropdown-header " style={{display:'flex', flexDirection:'row', justifyContent:'start', alignContent:'start', alignItems:'center'}}>
                <LocalPhoneIcon
                  style={{ paddingRight: "0.5rem", color: "#F990CB" }}
                />
                <span className="tablet_mobile">Darko +389 70340499  {"(Booking)"}</span>
              </div>
            </div>
            <div className="dropdown">
            <div className="dropdown-header " style={{display:'flex', flexDirection:'row', justifyContent:'start', alignContent:'start', alignItems:'center'}}>
                <EmailIcon
                  style={{ paddingRight: "0.5rem", color: "#F990CB" }}
                />
                <span className="tablet_mobile">youngdadi.info@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isMenuOpened || contact || spotify ? (
        <></>
      ) : (
        <>
          {" "}
          <div>
            <div id="social_media">
              <span className="contact_info_icons_bottom">
                <YouTubeIcon
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                      "_blank"
                    );
                  }}
                  className="soacial_icon_css"
                />
                <InstagramIcon
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/youngdadii/",
                      "_blank"
                    );
                  }}
                  className="soacial_icon_css"
                />
                {/* <FacebookIcon
                   onClick={()=>{
                    window.open(
                            "https://www.youtube.com/watch?v=ouAt-SoCiy4&list=UULFKdArN5DO34n_oDwng8Xe9w",
                            "_blank"
                          );
                  }}
                  className="icon_css"/> */}
                {/* <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <TikTokIcon></TikTokIcon></div> */}
                {/* <EmailIcon style={{background:'#DA327D', padding:'0.2rem', borderRadius:8, margin:'0.3rem'}} /> */}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
